<template>
  <v-container style="min-height: 60vh">
    <!-- <h1>Home</h1> -->
    <div class="d-flex flex-row">
      <div class="my-auto">
        <v-hover v-slot="{ hover }">
          <v-img
            src="../assets/resize.jpg"
            max-height="300"
            max-width="300"
            class="mx-auto rounded-circle"
            >
            
            <v-fade-transition>
              <v-img
              v-if="hover"
                src="../assets/feebyx.jpg"
                style="opacity: 0.5;"
                max-height="300"
                max-width="300"
                class="mx-auto rounded-circle"
              >
            </v-img>
          </v-fade-transition>
        </v-img>
        </v-hover>
        <v-divider style="background-color: #FD7014;width: 30%;" class="mx-auto mt-3"></v-divider>
        <h2 style="color: #EEE;" class="text-center font-weight-light mt-4">Youenn Jamard</h2>
        <v-divider style="background-color: #FD7014;width: 30%;" class="mx-auto mt-3"></v-divider>
        <h3 style="color: #EEE;" class="text-center font-weight-light mt-4"> &lt; Développeur /&gt;</h3>
        <h3 style="color: #EEE;" class="text-center font-weight-light mt-2"> Vannes, Morbihan.</h3>
      </div>


      <div>
        <v-card
          v-for="card in cards"
          :key="card.text"
          outlined
          shaped
          class="ml-12 my-5"
          min-width="200"
          style="background-color: #393E46;"
        >
          <v-card-title class="pt-4">
            <h2 class="font-weight-medium" style="color: #FD7014;">
              {{ card.title }}
            </h2>
          </v-card-title>
          <v-card-text class="text-center justify-center pb-4" >
            <p class="font-weight-medium subtitle-1" style="color: #EEE;">
              {{ card.text }}
            </p>
          </v-card-text>
        </v-card>
      </div>
    </div>




  </v-container>
</template>

<script>

  let d = new Date();
  let before = (d.getMonth()+1 < 4) || (d.getMonth()+1 == 4) && (d.getDate() <= 11); 

  export default {
    name: 'HomePage',
    components: {

    },

    data: () => ({
      image: "../assets/Youenn.jpg",
      image2: "../assets/feebyx.jpg",
      cards: [
        {
          title: "Qui suis-je ?",
          text: `Youenn Jamard, j'ai ${before ? d.getFullYear()-2004 : d.getFullYear()-2003} ans, je suis actuellement étudiant ingénieur en Cyberdéfense à l'ENSIBS.`
        },
        {
          title: "Qu'est-ce que je fais ?",
          text: "Je fais de l'infrastructure, du devops, de la sécurité informatique et du développement."
        },
        {
          title: "Quelles sont mes passions ?",
          text: "Je suis passionné par le développement notamment d'infratructure, la sécurité informatique, les jeux vidéo et la musique."
        },
      ]
    }),
  }
</script>
