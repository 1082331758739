<template>
  <v-container>
    <h1>Mon Parcours : </h1>
    <v-timeline 
      :dense="$vuetify.breakpoint.smAndDown"
      dark
    >
      <v-timeline-item
        
        v-for="(item, i) in items"
        :key="i"
        :color="item.color"
        :fill-dot="item.fillDot"
        :icon="item.icon"
        :large="item.large"
        :left="item.left"
        :right="item.right"
        :opposite="item.opposite"
      >
        <template v-if="item.opposite" v-slot:opposite>
          <v-card>
            <v-card-title style="background-color: #FD7014;">
              <v-icon
                dark
                size="42"
                class="mr-4"
              >
                {{ item.icono }}
              </v-icon>
              <h2 class="text-h4 white--text font-weight-light">
                {{ item.titleo }}
              </h2>
            </v-card-title>
            <v-container style="background-color: #393E46;">
              <v-row>
                <v-col
                  cols="12"
                  md="10"
                >
                  {{ item.texto }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </template>

        <v-card>
          <v-card-title style="background-color: #FD7014;">
            <v-icon
              dark
              size="42"
              class="mr-4"
            >
              {{ item.icon }}
            </v-icon>
            <h2 class="text-h4 white--text font-weight-light">
              {{ item.title }}
            </h2>
          </v-card-title>
          <v-container style="background-color: #393E46;">
            <v-row>
              <v-col
                cols="12"
                md="10"
              >
                {{ item.text }}
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    
  </v-container>
</template>

<script>
  export default {
    name: 'TimeLine',

    data: () => ({
      items: [
        {
          fillDot: true,
          right: true,
          large: false,
          opposite: true,
          color: '#FD7014',
          icon: 'mdi-town-hall',
          title: 'Étudiant Ingénieur en alternance',
          date: '2023 - 2026',
          text: `
            J'ai intégrer l'ENSIBS en 2023 pour suivre une formation d'ingénieur spécialisé en sécurité des systèmes d'informations.
           `,
          titleo: 'Bystamp',
          texto: 'Gestion et développement d\'infrastructures, gestion de l\'authentification et des accès',
          icono: 'mdi-briefcase'
        },
        {
          fillDot: true,
          left: true,
          large: true,
          opposite: true,
          color: '#FD7014',
          icon: 'mdi-school',
          title: 'Classe Préparatoire',
          text: 'Je suis entré en classe préparatoire en 2021 à l\'ENSIBS. (Des cours supplémentaires avec des notions de cyber-sécurité me sont enseigné.)',
          date: '2018 - 2020',
          
          titleo: 'BUT Informatique',
          texto: 'En parallèle, je suis les cours du BUT Informatique à l\'IUT de Vannes. Je suis actuellement en deuxième année.',
          icono: 'mdi-school'
        },
        {
          fillDot: true,
          right: true,
          large: false,
          opposite: false,
          color: '#FD7014',
          icon: 'mdi-town-hall',
          title: 'Lycée',
          date: '2015 - 2018',
          text: `
            Je suis entré au lycée Alain René Lesage à Vannes en 2018. 
            J'ai obtenu mon baccalauréat de STI2D mention assez bien en 2021.
           `
        },
        
      ]
    }),
  }
</script>

<style>
.v-card__title {
  word-break: break-word;
}

</style>
